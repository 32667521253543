import { JBGShopify } from '$services/shopify'

export default defineNuxtPlugin(({ $i18n }) => {
    const runtimeConfig = useRuntimeConfig()
    const shopifyProvider = new JBGShopify(<JBGShopify.InitOptions>{
        ...runtimeConfig.public.shopify,
        locale: $i18n.locale.value
    })

    return {
        provide: {
            shopify: shopifyProvider
        }
    }
})
