<template>
<TransitionGroup name="list" tag="ul" class="toast">
    <li v-for="item in items" :key="item.id" class="toast-item" :class="item.type">
        <i class="fa-duotone fa-solid fa-bomb" :class="icon(item)" />
        <div class="toast-content">
            <p class="toast-text">{{ item.text }}</p>
            <p v-if="item.subtext" class="toast-subtext">{{ item.subtext }}</p>
        </div>
        <div class="toast-close" :onclick="removeItem(item.id)">
            <i class="fa-solid fa-close" />
        </div>
    </li>
</TransitionGroup>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Toast } from './plugin'

export default defineComponent({
    data() {
        return {
            items: <Array<Toast.Item>> [],
            isVisible: false
        }
    },

    beforeMount() {
        // @ts-ignore
        this.$toast.register(this)
    },

    methods: {
        add(item: Toast.Item) {
            this.items.push(item)
            if (item.duration !== 0) {
                if (!item.duration) {
                    // default to 15s if no duration is set.
                    item.duration = 15000
                }
                item.timeout = window.setTimeout(() => {
                    this.remove(item.id)
                }, item.duration)
            }
        },

        remove(id: string) {
            const item = this.items.find((i) => i.id === id)
            if (item) {
                this.items.splice(this.items.indexOf(item), 1)
                if (item.timeout) {
                    window.clearTimeout(item.timeout)
                }
            }
        },

        removeItem(id: string) {
            return () => {
                this.remove(id)
            }
        },

        icon(item: Toast.Item) {
            switch (item.type) {
            case 'error':
                return 'fa-bomb'
            case 'warning':
                return 'fa-triangle-exclamation'
            default:
                return 'fa-circle-check'
            }
        }
    }

})
</script>


<style lang="scss" scoped>

.toast {
    position: fixed;
    top: 0;
    right: 0;
    opacity: 1;
    width: 100%;
    max-width: 350px;
    z-index: 1000;
    background: transparent;
    list-style: none;
    margin: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;

    .toast-item {
        width: 100%;
        min-height: 56;
        padding: 12px;
        border-radius: 4px;
        color: var(--shade-100);
        display: flex;
        gap: 12px;

        background: var(--alt-blue-200);
        &.warning {
            background: var(--yellow-200);
        }
        &.error {
            background: var(--red-200);
        }

        .toast-content {
            flex: 1;
        }

        .toast-text {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 6px 0;
        }

        .toast-subtext {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
        }

        .toast-close {
            &:hover {
                color: var(--shade-0);
            }
        }
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
}

</style>
