import type { Component } from 'vue'
import Modal from './Modal.vue'

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $hideModal(): void

        /**
        * Shows a jbg styled error modal
        */
        $showModal(component: 'Error', props: Modal.ErrorModal.Options, options?: Modal.Options): Promise<void>

        /**
        * Shows a jbg styled options modal
        */
        $showModal(component: 'Options', props: Modal.OptionsModal.Options, options?: Modal.Options): Promise<string|void>

        /**
        * Shows a provided component as a modal
        */
        $showModal<T>(component: Component, props?: Record<string, any>, options?: Modal.Options): Promise<T|void>

        $registerModal(instance: typeof Modal): void
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    if (nuxtApp.vueApp.config.globalProperties.$showModal) return

    let instance: typeof Modal

    const hideModal = <T>(): (T|void) => {
        if (!instance) throw new Error('No ModalComponent is registered')
        return instance.onBackgroundClick()
    }

    const showModal = <T>(component: Component | string, props?: Record<string, any>, options?: Modal.Options): (T|void) => {
        if (!instance) throw new Error('No ModalComponent is registered')
        return instance.show(component, props, options)
    }

    const registerModal = (modal: typeof Modal) => {
        instance = modal
    }

    nuxtApp.vueApp.component('Modal', Modal as Component)
    nuxtApp.vueApp.config.globalProperties.$hideModal = hideModal
    nuxtApp.vueApp.config.globalProperties.$showModal = showModal
    nuxtApp.vueApp.config.globalProperties.$registerModal = registerModal

    const modal: Modal.Api = {
        hideModal,
        showModal,
        registerModal
    }

    return {
        provide: {
            modal
        }
    }
})

export namespace Modal {
    export interface Options {
        classes?: string | string[]
    }

    // leaving these in here in case we want them
    export namespace ErrorModal {
        export interface Options {
            image?: string
            text: string
            subtext?: string
            classes?: string | string[]
            dismissText: string
        }
    }

    export namespace OptionsModal {
        export interface Options {
            text?: string
            subtext?: string
            classes?: string | string[]
            options: {
                header?: string
                text: string
                buttonText?: string
                classes?: string | string[]
                value: string
            }[]
        }
    }

    export interface Api {
        showModal<T>(component: Component | string, props?: Record<string, any>, options?: Modal.Options): (T|void)
        hideModal<T>(): (T|void)
        registerModal(modal: typeof Modal): void
    }
}
