<template>
<transition name="modal-transition">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
        v-if="props"
        class="modal"
        :class="classes"
        v-on:keyup.esc="onBackgroundClick"
        v-on:click.self="onBackgroundClick"
    >
        <component
            :is="content"
            v-if="content"
            class="content"
            v-bind="props"
            v-on:resolve="onResolve"
            v-on:action="handleAction"
        />
    </div>
</transition>
</template>

<script lang="ts">
import type { Component } from 'vue'
import type { Modal } from './plugin'

export default defineNuxtComponent({
    emits: ['action'],
    data() {
        return {
            classes: <string[]|string> 'jbg',
            props: <Record<string, any>|null> null,
            resolve: <((...args: any[]) => void)|null> null,
            content: <Component | null> null
        }
    },

    mounted() {
        // @ts-ignore
        this.$registerModal(this)
    },

    methods: {
        show(component: Component | string, props: Record<string, any> = {}, options: Modal.Options = {}) {
            this.props = props
            this.classes = options.classes || 'jbg'
            this.content = component as Component

            return new Promise<any>((resolve) => {
                this.resolve = resolve as (...args: any[]) => void
            })
        },

        onResolve(...args: any[]) {
            this.props = null
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.resolve(...args)
        },

        onBackgroundClick() {
            this.props = null
            this.resolve()
        },

        handleAction(value: string) {
            this.$emit('action', value)
        }
    }
})
</script>

<style lang="scss" scoped>
@use "sass:color";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1000;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 60px);
    padding: 25px;
    border-radius: 20px;
    transform: translate(-50%, -50%);
}

.modal {
    background: color.adjust(#0D0F11, $alpha: -0.05);
}

.modal-transition-enter-active,
.modal-transition-leave-active {
    transition: opacity .15s;

    .content {
        transition: transform .25s;
    }
}

.modal-transition-enter,
.modal-transition-leave-to {
    opacity: 0;

    .content {
        transform: translate(-50%, -50%) scale(0.85);
    }
}
</style>
