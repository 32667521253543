import { type Component } from 'vue'
import Toast from './Toast.vue'

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $toast: Toast.Api
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    let instance: typeof Toast
    const toast: Toast.Api = {
        add: (item: Toast.Item) => {
            if (!instance) throw new Error('No ToastComponent is registered to show')
            instance.add(item)
        },

        remove: (id: string) => {
            if (!instance) throw new Error('No ToastComponent is registered to hide')
            instance.remove(id)
        },

        register: (toast: typeof Toast) => {
            instance = toast
        }
    }

    nuxtApp.vueApp.component('Toast', Toast as Component)
    nuxtApp.vueApp.config.globalProperties.$showToast = (options: Toast.Options) => {
        if (!instance) throw new Error('No ToastComponent is registered to show')
        instance.show(options)
    }
    nuxtApp.vueApp.config.globalProperties.$hideToast = () => {
        if (!instance) throw new Error('No ToastComponent is registered to hide')
        instance.hide()
    }
    nuxtApp.vueApp.config.globalProperties.$registerToast = (toast: typeof Toast) => {
        instance = toast
    }

    return {
        provide: {
            toast
        }
    }
})

export namespace Toast {
    export interface Item {
        type: 'error' | 'info' | 'warning'
        id: string
        text: string
        subtext?: string
        duration?: number
        timeout?: number
    }

    export interface Api {
        add(item: Toast.Item): void
        remove(id: string): void
        register(instance: typeof Toast): void
    }
}
