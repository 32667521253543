/* eslint-disable no-underscore-dangle */
import { reactive } from 'vue'
import { GtmSupport, useGtm } from '@gtm-support/vue-gtm'
import { AuthService } from './auth'
import { JBGApi } from './api'

export namespace User {
    export enum Status {
        Unknown,
        Verified,
        Unverified,
        CreatingByEmail,
        CreatingBySSO
    }
}

export const CurrentUser = reactive({
    id: 0,
    dob: '',
    email: '',
    isDirty: true,
    provider: '',
    mixpanelId: '',
    username: '',
    _isLoggedIn: false,
    _status: User.Status.Unknown,

    setData(user: UserApi.Response): void {
        this.id = user.id
        this.dob = user.dob ?? ''
        this.email = user.email ?? ''
        this.mixpanelId = user.mixpanelId ?? ''
        this.provider = user.provider ?? ''
        this.username = user.username ?? ''
        this._isLoggedIn = true
    },
    getStatus(): User.Status {
        return this._status
    },
    setStatus(status: User.Status) {
        this._status = status
    },
    logout(): void {
        this.id = 0
        this.dob = ''
        this.email = ''
        this.mixpanelId = ''
        this.provider = ''
        this.username = ''
        this._isLoggedIn = false
    },
    isLoggedIn(): boolean {
        return this._isLoggedIn
    }
})

export class UserApi extends JBGApi {
    private authService: AuthService
    private currentUser: typeof CurrentUser
    private gtm: GtmSupport | undefined

    constructor(options: UserApi.Options) {
        super(options.binjpipeUrl, options.authService)
        this.authService = options.authService
        this.currentUser = CurrentUser
        this.gtm = useGtm()
    }

    get isLoggedIn(): boolean {
        const accessExpirationCookie = document.cookie.indexOf('binjpipe-access-expiration-token=') !== -1
        if (accessExpirationCookie) {
            return true
        }
        return false
    }

    // signup via email/pwd for new users
    // eslint-disable-next-line max-len
    async emailSignup(email: string, password: string, confirmPassword: string, username: string, dob: string): Promise<Response|JBGApi.Error> {
        try {
            const route = 'user/emailsignup'
            const options = this.buildOptions('POST', {
                email,
                password,
                confirmPassword,
                username,
                dob
            })

            return this.callAPI(route, options)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    public async getUserByEmail(email: string): Promise<Response> {
        const route = `user/exists/${email}`
        const options = this.buildOptions('GET')
        return this.callAPI(route, options)
    }

    // login via Google auth
    async login() {
        const BINJ_ACCESS_COOKIE_EXPIRATION = 60
        // save the current date/time plus the expiration of the binjpipe-access-cookie - 1m to check against later
        const currentTime = new Date()
        const expirationTimeWindow = new Date(currentTime.setMinutes(currentTime.getMinutes() + (BINJ_ACCESS_COOKIE_EXPIRATION - 1)))

        try {
            await this.authService.login(AuthService.Providers.GOOGLE)

            sessionStorage.setItem('authExpiration', expirationTimeWindow.toString())
        } catch (error) {
            console.error(error)
        } finally {
            this.currentUser.isDirty = true
        }
    }
    // login via email
    async emailLogin(email: string, password: string): Promise<Response> {
        try {
            return this.authService.emailSignin(email, password)
        } finally {
            this.currentUser.isDirty = true
        }
    }

    async logout(): Promise<void> {
        try {
            await this.authService.logout(this.currentUser.id)
            if (this.gtm) {
                this.gtm.trackEvent({
                    event: 'Mixpanel - Reset'
                })
            }
            // clear out local data
            this.currentUser.logout()
        } catch (err) {
            // TODO: better error handling than this?
            console.error('failed to log user out.', err)
        }
    }

    async refreshToken() {
        const res = await this.authService.refreshToken()
        if (res.status !== 204) {
            // force log out
            void this.logout()
        }
    }

    async verifyEmail(code: string, email: string): Promise<Response|JBGApi.Error> {
        try {
            const route = 'user/verify'
            const options = this.buildOptions('POST', {
                code,
                email
            })

            return this.callAPI(route, options)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    async sendVerification(email: string): Promise<Response|JBGApi.Error> {
        try {
            const route = 'user/sendverification'
            const options = this.buildOptions('POST', {
                email
            })

            return this.callAPI(route, options)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    async checkUserName(username: string): Promise<Response|JBGApi.Error> {
        try {
            const route = `user/checkusername/${username}`
            const options = this.buildOptions('GET')

            return this.callAPI(route, options)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    async updatePassword(currentPassword: string, newPassword: string) {
        try {
            const route = 'user/changepassword'
            const options = this.buildOptions('POST', {
                currentPassword,
                newPassword
            })

            return this.callAPI(route, options)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    checkLastAuthenticated(userIsAuthenticated: boolean) {
        const authExpiration = sessionStorage.getItem('authExpiration')
        if (authExpiration) {
            // if user is not authenticated, remove lastAuthentication and return
            if (!userIsAuthenticated) {
                sessionStorage.removeItem('authExpiration')
                return
            }

            const currentTime = new Date()
            const authIsExpired = Date.parse(authExpiration) < Date.parse(String(currentTime))
            if (!authIsExpired) {
                return
            }

            // if expired, attempt a refresh
            void this.refreshToken()
        }
    }

    public async update(user: UserApi.UpdateRequest): Promise<Response|JBGApi.Error> {
        const route = 'user'
        const options = this.buildOptions('POST', {
            username: user.username,
            dob: user.dob,
            avatar: user.avatar
        })
        return this.callAPI(route, options)
    }

    public async loadUserData(): Promise<void|JBGApi.Error> {
        const route = 'user'
        const options = this.buildOptions('GET')
        try {
            const res = await this.callAPI<UserApi.Response>(route, options)
            if (res.mixpanelId) {
                if (this.gtm) {
                    this.gtm.trackEvent({
                        event: 'Mixpanel - Identify User',
                        value: res.mixpanelId
                    })
                }
            }
            this.currentUser.setData(res)
        } catch (error) {
            return error as JBGApi.Error
        } finally {
            this.currentUser.isDirty = false
        }
    }

    async forgotPassword(email: string): Promise<Response|JBGApi.Error> {
        try {
            return this.authService.forgotPassword(email)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    async confirmForgotPassword(email: string, code: string, password: string): Promise<Response|JBGApi.Error> {
        try {
            return this.authService.confirmForgotPassword(email, code, password)
        } catch (error) {
            return error as JBGApi.Error
        }
    }

    async deleteUser(): Promise<Response|JBGApi.Error> {
        const route = 'user'
        const options = this.buildOptions('DELETE')
        return this.callAPI(route, options)
    }
}

export namespace UserApi {
    export interface Options{
        authService: AuthService
        binjpipeUrl: string
    }

    export interface Response {
        id: number
        dob: string
        email: string
        username: string
        provider?: string
        mixpanelId?: string
    }

    export interface UpdateRequest {
        username?: string
        confirmPassword?: string,
        dob?: string
        avatar?: string
    }
}
