import type { ComponentPublicInstance } from 'vue'

import { BBCodeParser, Tag } from '@jackboxgames/bbcode-parser'

type BBTagDefinition = Tag.MarkupGenerator | { generator: Tag.MarkupGenerator, options: Tag.CreateOptions}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
        * Parses the provided string using the shared BBCode parser
        */
        $bb(string: string): string
    }

    interface ComponentCustomOptions {
        /**
        * Parses the provided string using the shared BBCode parser
        */
        bb?: Record<string, BBTagDefinition>
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const tags: Record<string, Tag> = {
        section: Tag.create('section', (_, content, { section }) => {
            const classTag = section
                ? `class="section ${section}"`
                : 'class="section"'

            return `<div ${classTag}>${content}</div>`
        })
    }

    const boldTags = ['b', 'bold', 'B']
    boldTags.forEach((tagName: string) => {
        // <b> elements should be used to draw attention, not convey importance or alter voice
        // <strong> elements should be used to represent importance or seriousness of contents
        tags[tagName] = Tag.create(tagName, (_, content) => `<strong>${content}</strong>`)
    })

    const italicTags = ['i', 'italic', 'I']
    italicTags.forEach((tagName: string) => {
        // <cite> elements should be used with CSS to represent the title of a work
        // <em> elements should be used to emphasize contents
        tags[tagName] = Tag.create(tagName, (_, content) => `<em>${content}</em>`)
    })

    // Make it easy to insert a query param as content
    tags.param = Tag.create('param', (_, _content, params) => {
        const searchParams = new URLSearchParams(window?.location.search)
        return searchParams.get(params.param)
    })

    const parser = new BBCodeParser(tags)

    nuxtApp.vueApp.directive('bb', {
        mounted(el, binding) {
            const tempEl = document.createElement('div')
            tempEl.textContent = binding.value
            el.innerHTML = parser.parse(tempEl.innerHTML)
        },

        updated(el, binding) {
            const tempEl = document.createElement('div')
            tempEl.textContent = binding.value
            el.innerHTML = parser.parse(tempEl.innerHTML)
        }
    })

    nuxtApp.vueApp.mixin({
        beforeCreate(this: ComponentPublicInstance) {
            if (!this.$options.bb) return

            Object.keys(this.$options.bb).forEach((key) => {
                const option = this.$options.bb[key]

                if (option instanceof Function) {
                    parser.addTag(key, Tag.create(key, option))
                    return
                }

                parser.addTag(key, Tag.create(key, option.generator, option.options))
            })
        }
    })

    nuxtApp.vueApp.config.globalProperties.$bb = (content: any) => {
        if (typeof content !== 'string') {
            console.warn(
                `[BBCodePlugin] Received unexpected ${typeof content} with value ${content};`
              + 'converting to string before parsing.'
            )
        }

        return parser.parse(String(content))
    }
})
