/**
 * A very simple static class for retrieving
 * a users country code (ISO 3166-1 alpha-2)
 * from `cloudfront-viewer-country` cookie
 */

export class Geolocate {
    static getCountryCode() {
        const defaultCountry = 'US'
        const cookieKey = 'cloudfront-viewer-country'
        // default to US if the `cloudfront-viewer-country` header is not present
        const cookieValue = useCookie(cookieKey).value
        if (!cookieValue) {
            return defaultCountry
        }
        return cookieValue
    }
}
