import type { Plugin } from 'vue'
import { UserApi } from '../services/user'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $user: UserApi,
    }
}

export const userPlugin: Plugin = {
    install: (app, options: UserApi.Options) => {
        app.config.globalProperties.$user = new UserApi(options)
    }
}

export default defineNuxtPlugin({
    name: 'user-api',
    dependsOn: ['auth-service'],
    setup(_nuxtApp) {
        const nuxtApp = useNuxtApp()
        const runtimeConfig = useRuntimeConfig()

        const userApiOptions: UserApi.Options = {
            authService: nuxtApp.$auth,
            ...runtimeConfig.public.user
        }
        const userApi = new UserApi(userApiOptions)
        return {
            provide: {
                user: userApi
            }
        }
    }
})
