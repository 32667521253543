import { I18n } from '$services/i18n'

export default defineNuxtRouteMiddleware((next, _prev) => {
    const { $i18n } = useNuxtApp()
    const localeRoute = useLocaleRoute()

    if (next.query.locale?.length > 0) {
        // The `localeRoute` composable is case-sensitive, so we need to
        // normalize the locale lookup string.
        const nextLocale = I18n.getNormalizedLocale(next.query.locale as I18n.Locale)

        if (I18n.supported.includes(nextLocale)) {
            void $i18n.setLocale(nextLocale)

            const nextRoute = localeRoute(next.path, nextLocale)
            nextRoute.query = { ...next.query, locale: undefined }

            console.debug('Found Locale query; Redirecting to', nextRoute)
            return navigateTo(nextRoute)
        }
    }
})
